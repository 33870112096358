import React from "react";
import fbicon from "../../images/landing-page/fbicon.svg";
import instaicon from "../../images/landing-page/instaicon.svg";
import youtubeicon from "../../images/landing-page/youtubeicon.svg";
import linkedinicon from "../../images/landing-page/linkedinicon.svg";

const LandingPageFooter = () => {
  return (
    <footer className="footer">
      <div className="my_container">
        <ul className="social_media_wrapper">
          <li>
            <a href="https://www.facebook.com/LaxmiFoodsBrand/" target="_blank">
              <img src={fbicon} alt="social media icon" loading="lazy" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/laxmifoodsbrand/?hl=en"
              target="_blank"
            >
              <img src={instaicon} alt="social media icon" loading="lazy" />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCA-ojVe3STlOlTOzGzZhYwQ/featured"
              target="_blank"
            >
              <img src={youtubeicon} alt="social media icon" loading="lazy" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/houseofspices/"
              target="_blank"
            >
              <img src={linkedinicon} alt="social media icon" loading="lazy" />
            </a>
          </li>
        </ul>
        <div className="links_wrapper">
          <span>
            <a href="/story">our story</a>
          </span>
          <span>
            <a href="/products">our products</a>
          </span>
          <span>
            <a href="/store-locator">store locator</a>
          </span>
          <span>
            <a href="/kitchen">laxmi's recipe's</a>
          </span>
          <span>
            <a href="/media">media</a>
          </span>
          <span>
            <a href="/blogs">blogs</a>
          </span>
          <span>
            <a href="/contact">contact us</a>
          </span>
        </div>
        <div className="terms_row">
          <span>Copyrights Reserved @Laxmi</span>
          <span>
            Crafted by{" "}
            <a href="https://www.togglehead.in/" target="_blank">
              @Togglehead.in
            </a>{" "}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default LandingPageFooter;
