export { default as newtag } from "./header/newtag.svg";
export { default as contestbackground } from "./general/Group 8215 (1).svg";
export { default as contestbackgroundmob } from "./general/Group 8215.svg";
export { default as AkbarPirani } from "./landing-page/testi/Akbar-Pirani.png";
export { default as BhavanaPatel } from "./landing-page/testi/Bhavana-Patel.png";
export { default as DianaHayden } from "./landing-page/testi/Diana-Hayden.png";
export { default as FamilyKarma } from "./landing-page/testi/Family-Karma.png";
export { default as FatimaAli } from "./landing-page/testi/Fatima-Ali.png";
export { default as MsPooja } from "./landing-page/testi/Ms-Pooja.png";
export { default as NikhilMerchant } from "./landing-page/testi/Nikhil-Merchant.png";
export { default as Priya } from "./landing-page/testi/Priya.png";
export { default as Samira } from "./landing-page/testi/Samira.png";
export { default as ShubhraRamineni } from "./landing-page/testi/Shubhra-Ramineni.png";
export { default as dropDownArrow } from "./landing-page/drop-down-arrow.svg";
export { default as diwalibanner } from "./Diwali/Ticker website_Golden Jublee-banner_v01.jpg";
export { default as diwalibannermob } from "./Diwali/Background-mobile.jpg";
export { default as diwalibannerbig } from "./Diwali/2560x1700.jpg";
export { default as diwalibackground } from "./Diwali/Golden-Jublee-banner-bg@2x.jpg";
export { default as formbackgorund } from "./Diwali/form-backgorund.png";
export { default as forminside } from "./Diwali/form-image.png";
export { default as tooltip } from "./Diwali/tooltip.png";
export { default as buttonbackground } from "./Diwali/button-background.png";
export { default as circlegift } from "./Diwali/circle-gift.png";
export { default as fiftyyears } from "./Diwali/50-years.png";
export { default as grandprice } from "./Diwali/Grand-prize.png";
export { default as chancetowin } from "./Diwali/Chance-to-win.png";
export { default as goldenbonanza } from "./Diwali/Golden-bonanza-text.png";
export { default as entercoupon } from "./Diwali/Enter-coupon.png";
export { default as shilpa } from "./Diwali/Shilpa.png";
export { default as aboutback } from "./Diwali/about-background.png";
export { default as aboutbacknew } from "./Diwali/about-back-new.png";
export { default as formbackmob } from "./Diwali/form-background-mob.png";
export { default as diwalirightarrow } from "./Diwali/diwali-right-arrow.svg";
export { default as diwalileftarrow } from "./Diwali/diwali-left-arrow.svg";
export { default as diwaliplayicon } from "./Diwali/diwali-playicon.png";
export { default as diwaligallery1 } from "./Diwali/diwaligallery1.png";
export { default as diwaligallery2 } from "./Diwali/diwaligallery2.png";
export { default as diwaligallery3 } from "./Diwali/diwaligallery3.png";
export { default as shilpaimage } from "./Diwali/shilpa-shetty-image.png";
export { default as aboutbackmob } from "./Diwali/about-mobile-back-2.png";
export { default as aboutbacksm } from "./Diwali/about-mobile-back.png";
export { default as leftarrow } from "./Diwali/left-arrow.svg";

//homepage images
export { default as biggestdhamaka1 } from "./homepage/biggest_dhamaka.jpg";
export { default as biggestdhamakamob } from "./homepage/diwalibanner-mob.jpg";
export { default as diwalibonanzadesk } from "./homepage/diwali-bonanza-1920-X-780.png";
export { default as diwalibonanzamob } from "./homepage/diwali-bonanza-720-X-1228.png";
export { default as diwalihomepage } from "./homepage/laxmi-new-1920x780.jpg";
export { default as diwalihomepagemob } from "./homepage/laxmi-new-720x1228.jpg";
export { default as winnerhomepage } from "./homepage/winner-banner-desktop.jpg";
export { default as winnerhomepagemob } from "./homepage/winner-banner-mob.jpg";


export { default as ramadanBannerDesktop } from "./homepage/Ramadan_banner_desk.png";
export { default as frozenRangeBannerDesktop } from "./homepage/frozen-range-laxmi-desktop.jpg";
export { default as exploreBanner } from "./homepage/Explore_banner.jpg";
export { default as ramadanBannerMobile } from "./homepage/Ramadan_banner_mob.png";
export { default as frozenRangeBannerMobile } from "./homepage/frozen-range-laxmi-mobile.jpg";
export { default as mbExploreBanner } from "./homepage/mbexplore_banner.jpg";
export { default as cricketBanner } from "./homepage/cricket_desktop.jpg";
export { default as mbCricketBanner } from "./homepage/cricket_mobile.jpg";





//Winners Page
export { default as shilpawinner } from "./Diwali/winners/shilpa_winner.png";
export { default as shilpawinnermob } from "./Diwali/winners/shilpawinner-mob.png";
export { default as voucherwinner } from "./Diwali/winners/voucher_winner.svg";
export { default as newvoucherwinner } from "./Diwali/winners/new-voucher_winner.svg";
export { default as goldvoucher } from "./Diwali/winners/gold_card.svg";
export { default as coin } from "./Diwali/winners/coin.png";
export { default as goldwinnerback } from "./Diwali/winners/gold_winner_back.png";
export { default as megawinnerback } from "./Diwali/winners/mega-winner-background.svg";
export { default as downarrow } from "./Diwali/winners/dropdown-arrow.png";
export { default as voucherwinnerpng } from "./Diwali/winners/voucher_winner-png.png";
export { default as megashilpa } from "./Diwali/winners/mega-shilpa-desk.png";

export { default as contestclosed } from "./homepage/contest-close-1920-780.jpg";
export { default as contestclosedmob } from "./homepage/contest_closed-mob.jpg";

// Landing page images
export { default as whiteplayicon } from "./landing-page/whiteplayicon.png";
export { default as redleftarrow } from "./landing-page/redleftarrow.svg";
export { default as redrightarrow } from "./landing-page/redrightarrow.svg";
export { default as testidesign } from "./landing-page/testidesign.png";
export { default as testiimg2 } from "./landing-page/testiimg2.png";
export { default as testiimg3 } from "./landing-page/testiimg3.png";
export { default as testiimg4 } from "./landing-page/testiimg4.png";
export { default as anjana } from "./landing-page/testi/anjana.png";
export { default as ayesha } from "./landing-page/testi/Ayesha.png";
export { default as mariumArif } from "./landing-page/testi/Marium Arif.png";
export { default as kunalLamba } from "./landing-page/testi/Kunal Lamba.png";
export { default as aarthiSampath } from "./landing-page/testi/Aarthi Sampath.png";
export { default as pujaReza } from "./landing-page/testi/PujaReza.png";
export { default as omiVaidya } from "./landing-page/testi/Omi Vaidya.png";
export { default as ashaShiv } from "./landing-page/testi/Asha Shiv.png";
export { default as unmuktChand } from "./landing-page/testi/Unmukt Chand.png";
export { default as rabiaAmmar } from "./landing-page/testi/Rabia Ammar.png";
export { default as fiftycontestclosed } from "./landing-page/testi/50y_contest_closed-desk.png";
export { default as fiftycontestclosedmob } from "./landing-page/testi/50y_contest_closed-mob.png";
export { default as kunallamba } from "./Diwali/kunal-lamba.jpg";
export { default as archanamundhe } from "./Diwali/archana-mundhe.jpg";
export { default as kirannivi } from "./Diwali/kiran-and-nivi.jpg";
export { default as diwalibannernew } from "./Diwali/golden-new-banner.jpg";
export { default as diwalibannernewmobile } from "./Diwali/golden-new-banner-mobile.jpg";
export { default as megabannernew } from "./Diwali/golden-new-banner-new.jpg";
export { default as megabannernewmobile } from "./Diwali/golden-new-banner-mobile-new.jpg";

// general
export { default as downloadIcon } from "../images/general/download-icon.svg";
export { default as productPDF } from "../images/general/Laxmi Catalogue.pdf";

export let images = {
  diwalibanner: {
    image: require("./Diwali/Ticker website_Golden Jublee-banner_v01.jpg"),
    alt: "diwali banner img",
  },
};
